import React from "react";

const App = () => {
  const bgImage = "/assets/img/jeep-safari.jpg";
  const logo = "/assets/img/logo.png";

  return (
    <div
      className="h-screen flex items-center justify-center bg-cover bg-center relative"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
     
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 to-black/40"></div>

     
      <div className="relative z-10 text-center text-white max-w-3xl px-6 sm:px-8 md:px-12 lg:px-16">
     
        <div className="w-32 h-32 sm:w-40 sm:h-40 lg:w-56 lg:h-56 rounded-full mx-auto bg-white p-3 sm:p-4 lg:p-6 mb-8 lg:mb-12 shadow-2xl">
          <img
            src={logo}
            alt="Company Logo"
            className="w-full h-full object-contain rounded-full"
          />
        </div>

       
        <h1 className="text-4xl sm:text-5xl lg:text-7xl font-extrabold mb-4 sm:mb-6 tracking-wide drop-shadow-2xl">
          Launching Soon!
        </h1>

        <p className="text-lg sm:text-xl lg:text-2xl mb-6 lg:mb-10 text-gray-300 leading-relaxed">
          We’re creating something extraordinary. Get ready to experience it. Stay tuned for the big reveal!
        </p>

        <div className="flex flex-wrap justify-center gap-4 sm:gap-6">
          <button
            className="bg-gradient-to-r from-[#F50706] to-[#4A463E] text-white px-5 py-3 sm:px-7 sm:py-4 lg:px-10 lg:py-5 rounded-xl text-base sm:text-lg lg:text-xl font-bold shadow-md hover:shadow-lg hover:scale-105 transition-transform focus:outline-none focus:ring-4 focus:ring-purple-400"
            aria-label="Notify Me"
          >
            Notify Me
          </button>
          <button
            className="bg-white text-black px-5 py-3 sm:px-7 sm:py-4 lg:px-10 lg:py-5 rounded-xl text-base sm:text-lg lg:text-xl font-bold shadow-md hover:shadow-lg hover:scale-105 transition-transform focus:outline-none focus:ring-4 focus:ring-gray-400"
            aria-label="Contact Us"
          >
            Contact Us
          </button>
        </div>

      </div>
    </div>
  );
};

export default App;
